import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import NavBar from "./components/layout/Navbar";
import { useEffect, useState } from "react";
import MarkdocRenderer from "./markdoc/MarkdocRenderer";
import { useTranslation } from "react-i18next";

const pagePaths = [
  { path: "/", mdFile: "home.md" },
  { path: "/company-guides", mdFile: "company-guides.md" },
  { path: "/after-sales", mdFile: "after-sales.md" },
  { path: "/api", mdFile: "api.md" },
  { path: "/support", mdFile: "support.md" },
];

export type MarkdownIndex = {
  title: string;
  path: string;
  content: string;
  translatedContent: string;
}[];

const App = () => {
  const { t, i18n } = useTranslation();
  const [markdownIndex, setMarkdownIndex] = useState([] as MarkdownIndex);

  useEffect(() => {
    const loadMarkdownContent = async () => {
      const index = await Promise.all(
        pagePaths.map(async (page) => {
          const content = await fetch(`/md/${page.mdFile}`).then((res) =>
            res.text()
          );
          const translatedContent = content.replace(
            /t\('([^']*)'\)/g,
            (match, key) => {
              return t(key);
            }
          );
          return {
            title: page.path,
            path: page.path,
            content,
            translatedContent,
          };
        })
      );
      setMarkdownIndex(index);
    };
    loadMarkdownContent();
  }, []);

  useEffect(() => {
    const updatedIndex = markdownIndex.map((item) => ({
      ...item,
      translatedContent: item.content.replace(/t\('([^']*)'\)/g, (match, key) =>
        t(key)
      ),
    }));
    setMarkdownIndex(updatedIndex);
  }, [i18n.language]);

  return (
    <Router>
      <NavBar markdownIndex={markdownIndex} />
      <Box sx={{ padding: "30px", maxWidth: "1000px", margin: "0 auto", display: 'flex', }}>
        <Routes>
          {markdownIndex.map((mdIndex) => (
            <Route
              key={mdIndex.path}
              path={mdIndex.path}
              element={<MarkdocRenderer content={mdIndex?.translatedContent ?? ""} />}
            />
          ))}
        </Routes>
      </Box>
    </Router>
  );
};

export default App;
