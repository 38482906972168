import React from "react";
import { IconButton, Popover, Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

const LangSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    handleClose();
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        left: 16,
        zIndex: 1300,
      }}
    >
      <IconButton onClick={handleOpen}>
        <ReactCountryFlag
          countryCode={i18n.language === "fr" ? "FR" : "GB"}
          svg
          style={{
            width: "24px",
            height: "16px",
          }}
          title={i18n.language === "fr" ? "Français" : "English"}
        />
      </IconButton>

      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLanguageChange("en")}>
              <ReactCountryFlag countryCode="GB" svg style={{ width: "24px", height: "16px", marginRight: 8 }} />
              <ListItemText primary="English" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLanguageChange("fr")}>
              <ReactCountryFlag countryCode="FR" svg style={{ width: "24px", height: "16px", marginRight: 8 }} />
              <ListItemText primary="Français" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

export default LangSelector;
