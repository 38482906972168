import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Link,
  Grid2 as Grid,
  Box,
  useTheme,
  List,
  ListItem,
} from "@mui/material";

interface FeatureBlockProps {
  title: string;
  description: string;
  link: string;
}

export const FeatureBlock: React.FC<FeatureBlockProps> = ({
  title,
  description,
  link,
}) => {
  const theme = useTheme();
  return (
    <Link
      href={link}
      underline="none"
      sx={{ display: "block", height: "100%" }}
    >
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          height: "100%",
          transition: "box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: theme.shadows[4],
          },
        }}
      >
        <CardContent>
          <Typography variant="h6" component="h3" gutterBottom color="primary">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

interface GridLayoutProps {
  children: React.ReactNode;
}

export const GridLayout: React.FC<GridLayoutProps> = ({ children }) => {
  return (
    <Grid container spacing={3} sx={{ pb: 3 }}>
      {React.Children.map(children, (child, index) => (
        <Grid size={{ xs: 12, md: 4 }} key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

interface FeaturesListProps {
  children: React.ReactNode;
}

export const FeaturesList: React.FC<FeaturesListProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box sx={{ paddingLeft: 2, mb: 6 }}>
      <List sx={{ listStyle: "none", padding: 0 }}>
        {React.Children.map(children, (child, index) => (
          <ListItem
            key={index}
            disableGutters
            sx={{
              display: "flex",
              alignItems: "center",
              "&::before": {
                content: '""',
                display: "inline-block",
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: theme.palette.primary.main,
                marginRight: 1,
              },

              "& *": {
                margin: 0,
              },
            }}
          >
            {child}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
