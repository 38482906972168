const config = {
  nodes: {
    heading: {
      render: 'Heading',
      attributes: {
        level: { type: Number, required: true },
      }
    }
  },
  tags: {
    text: {
      render: "TextBlock",
    },
    section: {
      render: "SectionBlock",
      attributes: { title: { type: String } },
    },
    callout: {
      render: "Callout",
      attributes: {
        type: {
          type: String,
          matches: ["note", "warning", "tip"],
          default: "note",
        },
        title: { type: String },
      },
    },
    features: {
      render: "FeaturesList",
    },
    footer: {
      render: "FooterNote",
    },
    featureBlock: {
      render: "FeatureBlock",
      attributes: {
        title: { type: String, required: true },
        description: { type: String, required: true },
        link: { type: String, required: true },
      },
    },
    gridLayout: {
      render: "GridLayout",
    },
    apiEndpoint: {
      render: "ApiEndpoint",
      attributes: {
        title: { type: String, required: true },
        method: { type: String, required: true },
        path: { type: String, required: true },
      },
    },
    tableParams: {
      render: "TableParams",
      attributes: {
        title: { type: String, required: true },
        headers: { type: [String], required: true },
      },
      children: ["param"],
    },
    param: {
      render: "Param",
      attributes: {
        name: { type: String, required: true },
        type: { type: String, required: true },
        description: { type: String },
        required: { type: Boolean, default: false },
      },
    },
    codeExample: {
      render: "CodeExample",
      attributes: {
        title: { type: String },
        language: { type: String, default: "typescript" },
      },
    },
    pdfDownload: {
      render: 'PDFDownload',
      attributes: {
        src: { type: String, required: true },
        title: { type: String, required: true },
        description: { type: String, required: false },
      },
    },
  },
};

export default config;
