import React, { useState } from "react";
import {
  Typography,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { blue, green, orange, red } from '@mui/material/colors';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Check, ContentCopy } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface ApiEndpointProps {
  title: string;
  method: string;
  path: string;
  children?: React.ReactNode;
}

export const ApiEndpoint: React.FC<ApiEndpointProps> = ({
  title,
  method,
  path,
  children,
}) => {

  // Map HTTP methods to corresponding colors
  const methodColors: { [key: string]: string } = {
    GET: blue[500],
    POST: green[500],
    PUT: orange[500],
    DELETE: red[500],
  };

  // Get the color for the current method, default to grey if not specified
  const methodColor = methodColors[method.toUpperCase()] || 'text.primary';


  return (
    <Box sx={{ mb: 4}}>
      <Typography variant="subtitle1" gutterBottom sx={{padding: "3px", width: "fit-content", border: "1px dashed var(--mui-palette-primary-main)", borderRadius: "5px"}}>
        <Typography
          component="span"
          sx={{ color: methodColor, fontWeight: 'bold' }}
        >
          {method.toUpperCase()}
        </Typography>{' '}
        {`https://api.moncasierfrais.fr${path}`}
      </Typography>
      {children}
    </Box>
  );
};

interface ParamProps {
  name: string;
  type: string;
  description?: string;
}

export const Param: React.FC<ParamProps> = ({
  name,
  type,
  description,
}) => {
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{description}</TableCell>
    </TableRow>
  );
};

interface TableParamsProps {
  title: string;
  headers: string[];
  children: React.ReactNode;
}

export const TableParams: React.FC<TableParamsProps> = ({
  title,
  headers,
  children,
}) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle1">{title}</Typography>
      <TableContainer component={Paper} sx={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.3)" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  sx={{
                    padding: '4px 10px',
                    fontWeight: 'bold',
                  }}
                  key={header}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ "tr:nth-of-type(odd)": { backgroundColor: "#ddd" } }}>{children}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

interface CodeExampleProps {
  title?: string;
  language?: string;
  children: React.ReactNode;
}

export const CodeExample: React.FC<CodeExampleProps> = ({
  title,
  language = 'text',
  children,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const extractTextFromReactNode = (node: React.ReactNode): string => {
    if (typeof node === 'string') {
      if (node.trim() === '') {
        return '';
      }
      return `${node}\n`;
    } else if (Array.isArray(node)) {
      return node.map(extractTextFromReactNode).join('');
    } else if (React.isValidElement(node)) {
      if (node.type === 'br') {
        return '\n';
      }
      return extractTextFromReactNode(node.props.children);
    } else {
      return '';
    }
  }
  const codeContent = extractTextFromReactNode(children);

  const handleCopyCode = () => {
    navigator.clipboard.writeText(codeContent).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Revert after 2 seconds
    });
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'rgba(240, 240, 240, 1)',
          color: '#333',
          paddingX: 2,
          paddingY: 1,
          fontSize: '0.875rem',
          fontFamily: 'monospace',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="caption" sx={{ fontWeight: 'bold', color: '#555' }}>
          {title || language.toUpperCase()}
        </Typography>

        <Button
          size="small"
          onClick={handleCopyCode}
          startIcon={copied ? <Check /> : <ContentCopy />}
          sx={{
            color: copied ? '#4CAF50' : '#555',
            fontSize: '0.75rem',
            textTransform: 'none',
            padding: '0 6px',
            minWidth: 'auto',
            transition: 'color 0.2s ease',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
          }}
        >
          {copied ? t('text.copied') : t('text.copy')}
        </Button>
      </Box>

      <Paper
        elevation={1}
        sx={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <SyntaxHighlighter
          language={language}
          style={oneDark}
          customStyle={{ margin: 0, padding: '16px', background: '#282c34' }}
        >
          {codeContent}
        </SyntaxHighlighter>
      </Paper>
    </Box>
  );
};