import { Card, CardContent, Typography, Link, useTheme } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const PDFDownload = ({ src, title, description }: { src: string, title: string, description: string }) => {
    const theme = useTheme();
    return (
        <Link
            href={src}
            underline="none"
            target="_blank"
            sx={{ display: "block", height: "100%" }}
        >
            <Card
                variant="outlined"
                sx={{
                    borderRadius: 2,
                    height: "100%",
                    transition: "box-shadow 0.3s ease",
                    "&:hover": {
                        boxShadow: theme.shadows[4],
                    },
                }}
            >
                <CardContent>
                    <PictureAsPdfIcon fontSize="large" />
                    <Typography variant="h6" component="h3" gutterBottom color="primary">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    );
};

export default PDFDownload;
