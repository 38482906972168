import React, { ReactNode } from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { blue } from '@mui/material/colors';

interface TextBlockProps {
  children: ReactNode;
}
export const TextBlock: React.FC<TextBlockProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box mb={4}>
      <Typography variant="body2" sx={{ fontWeight: theme.typography.fontWeightLight }}>
        {children}
      </Typography>
    </Box>
  );
};

interface SectionBlockProps {
  title: string;
  children: ReactNode;
}
export const SectionBlock: React.FC<SectionBlockProps> = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box mb={6}>
      <Typography variant="h5" sx={{ fontWeight: theme.typography.fontWeightBold, mb: 2 }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: theme.typography.fontWeightLight }}>
        {children}
      </Typography>
    </Box>
  );
};

interface CalloutProps {
  type?: "note" | "warning" | "error";
  title?: string;
  children: ReactNode;
}
export const Callout: React.FC<CalloutProps> = ({ type = "note", title, children }) => {
  const theme = useTheme();
  let backgroundColor = theme.palette.info.light;

  if (type === "warning") {
    backgroundColor = theme.palette.warning.light;
  } else if (type === "error") {
    backgroundColor = theme.palette.error.light;
  } else if (type === "note") {
    backgroundColor = blue[50];
  }

  return (
    <Box sx={{ backgroundColor, padding: 2, borderRadius: 2, mb: 4 }}>
      {title && (
        <Typography variant="subtitle1" sx={{ fontWeight: theme.typography.fontWeightBold, mb: 1 }}>
          {title}
        </Typography>
      )}
      <Typography variant="body2" sx={{ fontWeight: theme.typography.fontWeightLight }}>
        {children}
      </Typography>
    </Box>
  );
};

interface FooterNoteProps {
  children: ReactNode;
}
export const FooterNote: React.FC<FooterNoteProps> = ({ children }) => (
  <Box component="footer" sx={{ fontStyle: "italic", mt: 4, textAlign: "center", color: "text.secondary" }}>
    <Typography variant="body2" sx={{ fontWeight: "light" }}>
      {children}
    </Typography>
  </Box>
);