import React from 'react';

interface HeadingProps {
  level: number;
  children: React.ReactNode;
  id?: string;
}

export const renderers = {
  Heading: (props: HeadingProps): JSX.Element => {
    const { level, children } = props;
    const id = typeof children === "string" ? children.toLowerCase().replace(/\s+/g, "-") : '';
    const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;

    return (
      <HeadingTag id={id} style={{ scrollMarginTop: '80px' }}>
        <a href={`#${id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
          {children}
        </a>
      </HeadingTag>
    );
  }
};
