import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Popper,
  Paper,
} from "@mui/material";
import { Search, ExpandMore, Menu as MenuIcon } from "@mui/icons-material";
import logoInline from "../../assets/img/logo-inline.png";
import LangSelector from "./LangSelector";
import { searchMarkdown, SearchResult } from "../../utils/search";
import { MarkdownIndex } from "../../App";

const NavBar: React.FC<{ markdownIndex: MarkdownIndex }> = ({
  markdownIndex,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setQuery(query);
    const results = searchMarkdown(query, markdownIndex);
    setSearchResults(results);
    setAnchorEl(event.currentTarget);
  };

  const highlightQuery = (text: string) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, "gi");
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span
          key={index}
          style={{
            fontWeight: 500,
            color: theme.palette.primary.main,
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const getAnchor = (element: string) => `#${t(element).toLowerCase().replace(/\s+/g, "-")}`;

  const menuItems = [
    { label: t("nav.home"), link: "/" },
    {
      label: t("nav.companyGuides"),
      link: "/company-guides", // Main page link
    },
    {
      label: t("nav.afterSales"),
      link: "/after-sales", // Main page link
    },
    {
      label: t("nav.api"),
      link: "/api", // Main page link
      subMenu: [
        { label: t("nav.getStarted"), anchor: getAnchor("api.title") },
        { label: t("nav.support"), anchor: getAnchor("api.supportTitle") },
        { label: t("nav.authentication"), anchor: getAnchor("api.authenticationTitle") },
        { label: t("nav.routes"), anchor: getAnchor("api.routesTitle") },
        { label: t("nav.pricing"), anchor: getAnchor("api.pricingTitle") },
      ],
    },
    {
      label: t("nav.feedback"),
      link: "https://suivi.moncasierfrais.fr",
      external: true,
    },
    { label: t("nav.support"), link: "/support" },
  ];

  const [anchorEls, setAnchorEls] = React.useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    menuLabel: string
  ) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [menuLabel]: event.currentTarget,
    }));
  };

  const handleMenuClose = (menuLabel: string) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [menuLabel]: null,
    }));
  };

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: "none",
        backgroundColor: "#F6F9FC",
        padding: "0px 20px",
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Box display="flex" alignItems="center">
          <Typography
            component={Link}
            to="/"
            variant="h6"
            sx={{
              color: theme.palette.text.primary,
              textDecoration: "none",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logoInline}
              alt="Logo"
              height="50px"
              style={{ marginRight: "8px" }}
            />
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              marginLeft: "8px",
              backgroundColor: "#E0F7FA", // Optional: a light background to highlight "DOCS"
              padding: "2px 6px",
              borderRadius: "4px",
              fontSize: "16px",
            }}
          >
            DOCS
          </Typography>
        </Box>

        {!isMobile ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            {menuItems.map((item) =>
              item.subMenu ? (
                <React.Fragment key={item.label}>
                  <Button
                    onClick={(e) => handleMenuOpen(e, item.label)}
                    endIcon={<ExpandMore />}
                    sx={{
                      color: theme.palette.text.primary,
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {item.label}
                  </Button>
                  <Menu
                    anchorEl={anchorEls[item.label]}
                    open={Boolean(anchorEls[item.label])}
                    onClose={() => handleMenuClose(item.label)}
                  >
                    {item.subMenu.map((subItem) => (
                      <MenuItem
                        key={subItem.label}
                        component={Link}
                        to={`${item.link}${subItem.anchor}`}
                        onClick={() => handleMenuClose(item.label)}
                        sx={{ color: theme.palette.text.primary }}
                      >
                        {subItem.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </React.Fragment>
              ) : (
                <Button
                  key={item.label}
                  component={item.external ? "a" : Link}
                  to={item.external ? undefined : item.link}
                  href={item.external ? item.link : undefined}
                  target={item.external ? "_blank" : undefined}
                  rel={item.external ? "noopener noreferrer" : undefined}
                  sx={{
                    color: theme.palette.text.primary,
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {item.label}
                </Button>
              )
            )}
          </Box>
        ) : (
          <IconButton onClick={() => toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        )}

        {!isMobile && (
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
                padding: "4px 8px",
                marginRight: 2,
              }}
            >
              <Search sx={{ color: "#4F566B" }} />
              <InputBase
                placeholder={t("text.search")}
                value={query}
                onChange={handleSearch}
                sx={{
                  fontSize: "14px",
                  color: "#4F566B",
                  "&::placeholder": { color: "#4F566B", opacity: 1 },
                }}
              />
            </Box>

            <Popper
              open={Boolean(searchResults.length)}
              anchorEl={anchorEl}
              placement="bottom-start"
              style={{ zIndex: 1300 }}
            >
              <Paper
                elevation={3}
                sx={{ mt: 1, width: 300, maxHeight: 400, overflowY: "auto" }}
              >
                <List>
                  {searchResults.map((result, index: number) => (
                    <ListItemButton
                      key={index}
                      component={Link}
                      to={`${result.path}#${result.anchor}`} // Updated to include anchor
                      sx={{ textDecoration: "none", color: "inherit" }}
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2">
                            {result.title}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            {highlightQuery(result.snippet)}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Paper>
            </Popper>
          </Box>
        )}
      </Toolbar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <List sx={{ width: 250 }}>
          {menuItems.map((item) =>
            item.subMenu ? (
              <React.Fragment key={item.label}>
                <ListItem>
                  <ListItemText primary={item.label} />
                </ListItem>
                {item.subMenu.map((subItem) => (
                  <ListItem key={subItem.label} disablePadding>
                    <ListItemButton
                      component={Link}
                      to={`${item.link}${subItem.anchor}`} // Ensure anchor is included
                      onClick={() => toggleDrawer(false)}
                    >
                      <ListItemText inset primary={subItem.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </React.Fragment>
            ) : (
              <ListItem key={item.label} disablePadding>
                <ListItemButton
                  component={item.external ? "a" : Link}
                  href={item.external ? item.link : undefined}
                  to={item.external ? undefined : item.link}
                  onClick={() => toggleDrawer(false)}
                >
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
        <LangSelector />
      </Drawer>
      {!isMobile && <LangSelector />}
    </AppBar>
  );
};

export default NavBar;
