import React, { useState } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Link,
    Drawer,
    IconButton,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import MenuIcon from '@mui/icons-material/Menu';

interface TableOfContentProps {
    headings: { id: string; level: number; title: string }[];
}

const TableOfContent: React.FC<TableOfContentProps> = ({ headings }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setDrawerOpen(open);
    };

    const tocContent = (
        <Box
            sx={{
                width: 250,
                padding: '16px',
                backgroundColor: blue[50],
                height: '100%',
            }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List disablePadding>
                {headings.map((heading, index) => (
                    <ListItem
                        key={index}
                        sx={{
                            paddingY: '2px',
                            paddingLeft: `${(heading.level - 1) * 12}px`,
                            position: 'relative',
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                right: '0',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '2px',
                                height: '100%',
                                opacity: 0,
                                backgroundColor: 'var(--mui-palette-primary-main)',
                                transition: '0.3s',
                            },
                            '&:hover::after': {
                                opacity: 1,
                            },
                        }}
                    >
                        <Link
                            href={`#${heading.id}`}
                            underline="none"
                            color="inherit"
                            sx={{
                                '&:hover': {
                                    textDecoration: 'none',
                                },
                            }}
                        >
                            <ListItemText
                                primaryTypographyProps={{ variant: 'body2' }}
                                primary={heading.title}
                            />
                        </Link>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <Box
                sx={{
                    height: 'fit-content',
                    position: 'sticky',
                    left: '100px',
                    top: '80px',
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block',
                    },
                }}
            >
                {tocContent}
            </Box>

            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: '16px',
                    right: '16px',
                    display: {
                        xs: 'flex',
                        sm: 'flex',
                        md: 'none',
                    },
                    backgroundColor: 'var(--mui-palette-primary-main)',
                    color: 'white',
                }}
                onClick={toggleDrawer(true)}
                aria-label="Open Table of Contents"
            >
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                    },
                }}
            >
                {tocContent}
            </Drawer>
        </>
    );
};

export default TableOfContent;
