import React, { useEffect, useState, useMemo } from "react";
import * as Markdoc from "@markdoc/markdoc";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import config from "./configs/markdoc.config";
import tagComponents from "../components/tags";
import TableOfContent from "../components/layout/TableOfContent";
import { renderers } from "./renderers";
import { useLocation } from "react-router-dom";

const MarkdocRenderer: React.FC<{ content: string }> = ({ content }) => {
  const { i18n } = useTranslation();
  const [headings, setHeadings] = useState<
    { id: string; level: number; title: string }[]
  >([]);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = decodeURIComponent(location.hash.substring(1));
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const ast = useMemo(() => Markdoc.parse(content), [content, i18n.language]);

  useEffect(() => {
    const extractHeadings = (node: any, headingsArr: any[]) => {
      if (node.type === "heading") {
        const level = node.attributes.level;
        if (level > 3) { 
          return "" 
        }
        const children = node.children || [];
        const title = children
          .map((child: any) => {
            if (child.type === "inline") {
              return child.children.map((subChild: any) => {
                if (subChild.type === "text") {
                  return subChild.attributes.content;
                }
                return "";
              }).join("");
            } else {
              return "";
            }
          })
          .join("");
        const id =
          node.attributes.id ||
          title
            .toLowerCase()
            .replace(/\s+/g, "-");
        headingsArr.push({ id, level, title });
        node.attributes.id = id;
      }

      if (node.children) {
        node.children.forEach((child: any) => extractHeadings(child, headingsArr));
      }
    };

    const headingsArr: { id: string; level: number; title: string }[] = [];
    extractHeadings(ast, headingsArr);
    setHeadings(headingsArr);
  }, [ast]);

  const contentRender = useMemo(() => Markdoc.transform(ast, config), [ast]);

  const renderedContent = useMemo(
    () =>
      Markdoc.renderers.react(contentRender, React, {
        components: { ...tagComponents, ...renderers },
      }),
    [contentRender]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <TableOfContent headings={headings} />
      <Box sx={{ flex: 1, paddingLeft: "16px" }} className="markdown-content">
        {renderedContent}
      </Box>
    </Box>
  );
};

export default MarkdocRenderer;
