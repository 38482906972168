import { TextBlock, SectionBlock, Callout, FooterNote } from "./SimpleTags";
import { FeaturesList, GridLayout, FeatureBlock } from "./FeatureBlocks";
import { ApiEndpoint, TableParams, Param, CodeExample } from "./ApiTags";
import PDFDownload from "./PDFDownload";

const tagComponents = {
  TextBlock,
  SectionBlock,
  Callout,
  FeaturesList,
  FooterNote,
  GridLayout,
  FeatureBlock,
  ApiEndpoint,
  TableParams,
  Param,
  CodeExample,
  PDFDownload,
};

export default tagComponents;
