import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Ubuntu, Arial, sans-serif", // Set the font family for the entire app
  },
  palette: {
    primary: {
      main: "#0F4547",
    },
    secondary: {
      main: "#73D0F9",
    },
    text: {
      primary: "#3C4257",
    },
  },
});

export default theme;
