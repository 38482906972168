import { MarkdownIndex } from "../App";

export type SearchResult = {
  title: string;
  snippet: string;
  path: string;
  anchor: string;
};

function generateId(title: string): string {
  return title.toLowerCase().toLowerCase().replace(/\s+/g, "-");;
}

function splitContentByHeadings(content: string): { heading: string; id: string; content: string }[] {
  const lines = content.split("\n");
  const sections: { heading: string; id: string; content: string }[] = [];
  let currentHeading = "";
  let currentId = "";
  let currentContent = "";

  lines.forEach((line) => {
    const headingMatch = line.match(/^(#{1,6})\s+(.*)$/);
    if (headingMatch) {
      if (currentHeading) {
        sections.push({
          heading: currentHeading,
          id: currentId,
          content: currentContent,
        });
      }
      currentHeading = headingMatch[2].trim();
      currentId = generateId(currentHeading);
      currentContent = "";
    } else {
      currentContent += line + "\n";
    }
  });

  if (currentHeading) {
    sections.push({
      heading: currentHeading,
      id: currentId,
      content: currentContent,
    });
  }

  return sections;
}

export function searchMarkdown(query: string, markdownIndex: MarkdownIndex): SearchResult[] {
  if (!query || query.length < 3) return [];

  const lowerQuery = query.toLowerCase();
  const results: SearchResult[] = [];

  markdownIndex.forEach((item) => {
    const { translatedContent, path } = item;
    const sections = splitContentByHeadings(translatedContent);

    sections.forEach((section) => {
      const sectionLower = section.content.toLowerCase();
      let startIndex = 0;

      while ((startIndex = sectionLower.indexOf(lowerQuery, startIndex)) !== -1) {
        const snippetStart = Math.max(0, startIndex - 50);
        const snippetEnd = Math.min(section.content.length, startIndex + 100);
        const snippet = section.content.substring(snippetStart, snippetEnd);

        results.push({
          title: item.title,
          snippet: `...${snippet}...`,
          path: path,
          anchor: section.id,
        });

        startIndex += lowerQuery.length;
      }
    });
  });

  return results;
}